import React from 'react';
import { graphql } from 'gatsby';
import ListingPage from '../components/ListingPage';

export default ({ data: { cover, corona } }) => (
  <ListingPage
    cover={cover}
    list={corona}
    linkPrefix="corona"
    title="Corona"
    subtitle="Reisen in sichere Gebiete während der Pandemie"
  />
);

export const query = graphql`
  query {
    cover: file(relativePath: { eq: "grass.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    corona: allSanityCorona {
      totalCount
      edges {
        node {
          title
          _rawExcerpt
          price
          mainImage {
            asset {
              fluid(maxWidth: 2048) {
                ...GatsbySanityImageFluid
              }
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`;
